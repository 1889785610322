import React from "react"
import "../sass/_404.scss"
import "../components/layout.scss"
import { SubpageLayout } from "../components/layout"
import SEO from "../components/seo"

const Covid19 = () => (
  <SubpageLayout>
    <SEO title="404: Not found" />
    <div className="container h-200">
      <div className="row h-100 align-items-left justify-content-left text-left">
        <div className="col-lg-6 col-md-8 col-sm-8 col-8 align-self-end" style={{marginTop: "100px"}}>
          <h3 className="text-uppercase text-white font-weight-bold" style={{paddingLeft: "15px"}}>
            Dear Patients and Associates of MacKay Dental Care,
          </h3>
        </div>
      </div>
    </div>
    <div className="container h-200">
    <div className="col-lg-6 col-md-8 col-sm-8 col-8 align-self-baseline">
      <p className="text-white-75 font-weight-light mb-5">
        <div align="left">
      
          <p></p>
          <p>We would like to assure all of you that we will continue to be open to serve your dental needs. </p>
          <p>We understand that there is much concern about the Coronavirus in our community and we all want to do our part in preventing the spread of this disease. </p>
          <p>Please know that we are making every effort to ensure that our office is a safe and clean place for people to receive dental treatment. As a healthcare provider we have always followed the standards of the CDC, OSHA, and the ADA.  We will continue to adhere to the highest health and sanitation standards. </p>
          <p>We are also committed to following the government sanctioned standards as this situation evolves.  Please help us in this cause by being willing to cancel your appoint if you are feeling ill in any form or feel like you might have any reason to be become ill. </p>
          <p>The safety of our patients and our staff is of the highest priority. Thank you for giving us the opportunity to serve you during this time of need and feel free to call and discuss any concerns.</p>
          <p>Sincerely, <br />
          Dr. Matthew MacKay and Staff</p>

        </div>
      </p>
      </div>
    
      
    </div>
    
    
  </SubpageLayout>
)

export default Covid19
